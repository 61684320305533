<template>
  <div class="arabic-ui">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<style>
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url(./fonts/gilroy/Gilroy-Regular.ttf) format("truetype");
}

.text-gilroy {
  font-family: "Gilroy";
}

@font-face {
  font-family: "GlacialIn";
  src: local("GlacialIn"),
    url(./fonts/GlacialIn/GlacialIndifference-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.text-glacial {
  font-family: "GlacialIn";
}

@font-face {
  font-family: "GlacialInBold";
  src: local("GlacialInBold"),
    url(./fonts/GlacialIn/GlacialIndifference-Bold.ttf) format("truetype");
  font-weight: bold;
}

.text-glacial {
  font-family: "GlacialInBold";
}

@font-face {
  font-family: "ArabicUI";
  src: local("ArabicUI"), url(./fonts/ArabicUIDisplay.ttf) format("truetype");
}

.arabic-ui {
  font-family: "ArabicUI";
}
</style>
