<template>
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <a class="navbar-brand logo-text" href="#">
        <img
          src="/assets/logos/logo.png"
          width="80"
          height="60"
          class="d-inline-block"
          alt=""
        />
        Deepo Cash
      </a>
      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#header"
              >الرئيسية</a
            >
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#services">الخدمات</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="#features">المميزات</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#aboutus">حولنا</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#faq">اسئلة متكررة</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/privacy_policy">سياسة الخصوصية</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <header id="header" class="header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="mb-4">
              <h5 class="h5-large">اول واكبر تطبيق جمعيات يومية</h5>
            </div>
            <h1 class="h1-large">ديبو كاش</h1>
            <p class="p-large">
              دلوقتي تقدر تحجز في اي جمعية من على التطبيق ومش محتاج تستنى كتير
            </p>
            <a class="btn-solid-lg" href="#download-section"
              >نزل التطبيق الآن</a
            >
          </div>
        </div>
        <div class="col-lg-6 col-xl-7 text-center">
          <img
            class="img-fluid"
            width="250"
            src="assets/images/app_snapshot.png"
            alt="alternative"
          />
        </div>
      </div>
    </div>
  </header>
  <div id="features" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-7">
          <div class="image-container">
            <img
              class="img-fluid"
              src="assets/images/details-1.png"
              alt="alternative"
            />
          </div>
        </div>
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="section-title">مميزات ديبو كاش</div>
            <!-- <h2>جمعيات يومية بدون رسوم ادارية</h2>
            <p>
              مع ديبو كاش تقدر تدخل جمعيات يومية ومن غير رسوم ادارية لكل مستخدم
              بيدخل جمعية عقد قانوني يضمنلك حقك
            </p> -->
            <h5>
              {{ deepoCashMerits }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="aboutus" class="basic-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-5">
          <div class="text-container">
            <div class="section-title">حولنا</div>
            <h2>
              ابليكشن ديبو كاش موثوق من اكتر من 10000 مستخدم من سبتمبر 2023 بس
            </h2>
            <!-- <p>
              Etiam tempus condimentum congue. In sit amet nisi eget massa
              condimentum lobortis eget ac eros. In hac habitasse platea
              dictumst. Aenean molestie mauris eget sapien sagittis, a bibendum
              magna tincidunt
            </p>
            <a class="btn-outline-reg" href="#">Details</a> -->
          </div>
        </div>
        <div class="col-lg-6 col-xl-7 text-center">
          <img
            class="img-fluid"
            src="assets/images/casual-life-3d-13.png"
            height="250"
            alt="alternative"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="download-section" class="slider-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>نزل التطبيق الآن</h4>
          <hr class="section-divider" />
        </div>
      </div>

      <br />

      <a href="https://play.google.com/store/apps/details?id=com.cw.deepo_pay">
        <img
          width="200"
          src="/assets/images/en_badge_web_generic.png"
          class="d-inline-block"
          alt=""
        />
      </a>
    </div>
  </div>
  <div id="faq" class="cards-2 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="h2-heading">الاسئلة المتكررة</h2>
        </div>
      </div>

      <div
        v-for="(faq, index) in faqs"
        :key="faq.q"
        class="accordion-item"
        dir="rtl"
      >
        <input type="checkbox" :id="'accordion' + index" />
        <label :for="'accordion' + index" class="accordion-item-title"
          ><span class="icon"></span>{{ faq.q }}</label
        >
        <div class="accordion-item-desc">
          <!-- {{ faq.ans }} -->
          <textarea
            v-model="faq.ans"
            rows="10"
            class="form-control"
            disabled
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="footer bg-gray">
    <img
      class="decoration-circles"
      src="assets/images/decoration-circles.png"
      alt="alternative"
    />
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h4>يمكنك التواصل معنا والاطلاع على خدماتنا من الروابط بالاسفل</h4>
          <div class="social-container">
            <span class="fa-stack">
              <!-- <a
                href="https://www.facebook.com/profile.php?id=61552112752330&mibextid=ZbWKwL"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a> -->
              <a
                href="https://www.facebook.com/share/g/GFPFYioLdGU2jzhS/?mibextid=CTbP7E"
              >
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack fa-stack-whatsapp">
              <a href="https://wa.me/201025173002?text=اهلا">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-whatsapp fa-stack-1x"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12 col-sm-12">
          <!-- <ul class="list-unstyled li-space-lg p-small">
            <li><a href="#">Article Details</a></li>
            <li><a href="#">Terms & Conditions</a></li>
            <li><a href="#">Privacy Policy</a></li>
          </ul> -->
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
          <p class="p-small statement">
            Copyright © <a href="#">Deepo Cash</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <button id="myBtn">
    <img src="assets/images/up-arrow.png" alt="alternative" />
  </button>
</template>

<script>
import WebsiteController from "@/controllers/WebsiteController";

export default {
  name: "HomeView",
  data() {
    return {
      faqs: [
        {
          q: `ليه جمعيات Deepo Cash أفضل من الجمعيات التقليدية والشهريه؟`,
          ans: "* لأن نظام السداد سيكون خفيفاً فلن تشعر أن المبلغ كبير. \n* بتقدم جمعيات يوميه من اول 10ج الي 200ج في اليوم . \n* بتقدم مدد مختلفه من اول 100 يوم الي 600 يوم. \n* المستخدم يقدر ينضم لأكتر من جمعية في نفس الوقت. \n* مش مقيد بدايرة معارفك أو موقعك الجغرافي. \n* فيها خصوصية ومش كل اللي حواليك عارفين هتقبض كام وامتى. \n* آمن، لأن كل المستخدمين بيوقعوا عقد قانوني للحفاظ على حقوقك وحقوقهم بكل أمان. \n* بتقدم طرق دفع وقبض مختلفة.",
        },
        {
          q: `إيه الموقف القانوني للشركة؟`,
          ans: `شركة Deepo CASH مسجلة بسجل تجاري رقم 130766، وبطاقة ضريبية رقم 755579011.`,
        },
        {
          q: `ليه أثق في باقي المستخدمين؟`,
          ans: `لأن كل مستخدم بيوقع عقد قانوني قبل بدء الجمعية، وده للحفاظ على حقوقك وحقوق كل المستخدمين وفي حالة تخلف بعض المشتركين عن دفع أقساطهم بتقوم Deepo Cash بتغطية الأدوار دي عشان قبضك يجيلك كامل .`,
        },
        {
          q: `إيه هي طرق الدفع؟`,
          ans: `تحويل بنكي
ماكينة ATM بنك مصر
أي محفظة إلكترونية في مصر`,
        },
        {
          q: `إيه هي طرق القبض؟`,
          ans: `* تحويل بنكي بتقدم طلب قبل القبض ب 5 ايام
* أي محفظة إلكترونية في مصر اساسي`,
        },
      ],
      deepoCashMerits:
        "مع ديبو كاش تقدر تدخل جمعيات يومية ومن غير رسوم ادارية لكل مستخدم بيدخل جمعية عقد قانوني يضمنلك حقك",
    };
  },
  async mounted() {
    await this.getWebsiteData();
  },
  methods: {
    async getWebsiteData() {
      try {
        const res = await WebsiteController.getWebsiteData();
        if (res.exists()) {
          const data = res.val();

          if (data !== null && data !== undefined) {
            if (data.faqs !== null && data.faqs !== undefined) {
              this.faqs = data.faqs;
            }

            if (
              data.deepoCashMerits !== null &&
              data.deepoCashMerits !== undefined
            ) {
              this.deepoCashMerits = data.deepoCashMerits;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.accordion {
  display: flex;
  flex-direction: column;
  font-family: "Sora", sans-serif;
  max-width: 991px;
  min-width: 320px;
  margin: 50px auto;
  padding: 0 50px;
}
.accordion h1 {
  font-size: 32px;
  text-align: center;
}
.accordion-item {
  margin-top: 16px;
  border: 1px solid #fcfcfc;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.accordion-item .accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 14px 20px;
  box-sizing: border-box;
  align-items: center;
}
.accordion-item .accordion-item-desc {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: #444;
  border-top: 1px dashed #ddd;
  padding: 10px 20px 20px;
  box-sizing: border-box;
}
.accordion-item input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc {
  display: block;
}
.accordion-item
  input[type="checkbox"]:checked
  ~ .accordion-item-title
  .icon:after {
  content: "-";
  font-size: 20px;
}
.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after {
  content: "+";
  font-size: 20px;
}
.accordion-item:first-child {
  margin-top: 0;
}
.accordion-item .icon {
  margin-left: 14px;
}

@media screen and (max-width: 767px) {
  .accordion {
    padding: 0 16px;
  }
  .accordion h1 {
    font-size: 22px;
  }
}
</style>
