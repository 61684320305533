// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgwtvwsICuE472ltKqARAa17XL7ODfUMo",
  authDomain: "deepopay-3ed4f.firebaseapp.com",
  databaseURL: "https://deepopay-3ed4f-default-rtdb.firebaseio.com",
  projectId: "deepopay-3ed4f",
  storageBucket: "deepopay-3ed4f.appspot.com",
  messagingSenderId: "280661468884",
  appId: "1:280661468884:web:4df3edd8efbf16c649bd41",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default app;
