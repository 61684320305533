import app from "@/firebase";
import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  push,
  onValue,
  update,
  query,
  orderByChild,
  equalTo,
  startAt,
  limitToFirst,
  orderByKey,
  limitToLast,
} from "firebase/database";
import Cookies from "js-cookie";

const db = getDatabase(app);

class WebsiteController {
  async getWebsiteData() {
    return await get(ref(db, "WebsiteData"));
  }

  async updateWebsiteData(data) {
    return await update(ref(db, "WebsiteData"), data);
  }
}

export default new WebsiteController();
